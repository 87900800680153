import React from 'react'
import { graphql } from 'gatsby'

const ManifestPage = ({
    data: {
        manifest: {
            data: {
                manifest
            }
        }
    }
}) => {
    return (
        <div dangerouslySetInnerHTML={{ __html: manifest.html }}></div>
    )
}

export const QUERY_MANIFESTPAGE = graphql`
    query ManifestPage($id: String!) {
        manifest: prismicManifestPage(id: { eq: $id }) {
            id
            lang
            ...ManifestPageQuery
        }
    }
`

export default ManifestPage